import React, { useEffect, useState } from "react";

export const AndroidTesting = () => {

    return (
        <div className="flex flex-col justify-center items-center bg-red-2 text-white min-h-[calc(100vh_-_45px)]">
            <img src="./logo.png" alt="PickPackGo Logo" className="w-28 mb-4"/>
            <div className="flex flex-col items-center mb-8 px-10 py-10 text-center border-2 border-red-400 md:mx-48 mx-4 rounded-lg">
                <h1 className="md:text-3xl text-xl">
                    Unfortunately, the Google Play Store requires new apps to explicitly specify who can download it.
                </h1>
                <p className="md:text-lg text-base italic font-light mt-5 text-red-100">
                    Please join our temporary Google Group <a className="underline font-bold" href="https://groups.google.com/u/1/g/pickpackgo-android-testing"> here</a>, which will give you permission to download PickPackGo on the Play Store. Then, install the app <a className="font-bold underline" href="https://play.google.com/store/apps/details?id=com.jgarcia084.pickpackgo"> here</a>!
                </p>
            </div>
        </div>
    );
};
