import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const Header = ({ currentPage }) => {
    const [popupActive, setPopupActive] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            {/* Header Container */}
            <div className="z-10 flex top-0 w-full h-[45px] items-center bg-neutral-100 px-3 justify-between text-red-2 text-sm">

                {/* Logo or Brand */}
                <button onClick={() => navigate("/")} className="font-bold text-lg">PickPackGo</button>

                {/* Desktop Navigation */}
                <div className="hidden sm:flex">
                    <button onClick={() => navigate("/")} className="hover:text-red-950 transition duration-200 font-normal pl-8">Home</button>
                    <button onClick={() => navigate("/plans")} className="hover:text-red-950 transition duration-200 font-normal pl-8">Pricing & Plans</button>
                    <button onClick={() => navigate("/privacyPolicy")} className="hover:text-red-950 transition duration-200 font-normal pl-8">Privacy Policy</button>
                    <button onClick={() => navigate("/terms")} className="hover:text-red-950 transition duration-200 font-normal pl-8">Terms of Service</button>
                </div>

                {/* Mobile Hamburger Icon */}
                <button 
                    className="sm:hidden flex items-center" 
                    onClick={() => setPopupActive(!popupActive)}
                >
                    <svg
                        className="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>

            {/* Mobile Popup Navigation */}
            {popupActive && (
                <div className="sm:hidden fixed top-[45px] left-0 w-full bg-neutral-100 shadow-lg z-20">
                    <button
                        onClick={() => {
                            navigate("/");
                            setPopupActive(false);
                        }}
                        className="block w-full text-left px-4 py-2 hover:bg-red-200"
                    >
                        Home
                    </button>
                    <button
                        onClick={() => {
                            navigate("/plans");
                            setPopupActive(false);
                        }}
                        className="block w-full text-left px-4 py-2 hover:bg-red-200"
                    >
                        Pricing & Plans
                    </button>
                    <button
                        onClick={() => {
                            navigate("/privacyPolicy");
                            setPopupActive(false);
                        }}
                        className="block w-full text-left px-4 py-2 hover:bg-red-200"
                    >
                        Privacy Policy
                    </button>
                    <button
                        onClick={() => {
                            navigate("/terms");
                            setPopupActive(false);
                        }}
                        className="block w-full text-left px-4 py-2 hover:bg-red-200"
                    >
                        Terms of Service
                    </button>
                </div>
            )}

            {/* Content Rendered by Routes */}
            <Outlet />
        </>
    );
};
