

export const Home = () => {
    
    return(
        <div className="flex flex-col justify-center">
            

            {/* Hero Image */}
            <div className="relative flex flex-row w-full h-auto bg-neutral-700">

                {/* Map */}
                <img className="absolute w-full h-full opacity-70 mix-blend-multiply brightness-50 contrast-200" 
                    style={{
                    backgroundImage: `url('./hero-image-map.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center 30%',
                    backgroundAttachment: 'fixed',
                }}/>

                <div className="relative flex flex-col w-full self-center mt-6 md:pt-32 pt-64 md:pb-48 pb-80">
                        <div className="px-5">
                            <div className="flex justify-center mr-5 items-start">
                                <img className="md:w-28 md:h-28 w-20 h-20 mr-4 slide-in-left" src="./logo.png"></img>
                                <p className="text-white md:text-6xl text-5xl text-center font-normal mt-5 md:mb-0 mb-7">PickPackGo</p>
                            </div>
                            <p className="text-white italic font-light md:text-[22px] text-lg text-center">An <a className="bg-white text-red-2 px-1">affordable</a> and <a className="bg-white text-red-2 px-1">secure</a> solution for package pickups.</p>
                            <p className="text-white italic font-light md:text-[22px] text-lg text-center">Powered by local businesses.</p>
                        </div>
                    <div className=" absolute flex top-0 right-5 flex-row justify-center space-x-1">
                        <a href="https://play.google.com/store/apps/details?id=com.jgarcia084.pickpackgo"><img className="md:w-36 md:mr-2 w-28" src="./Download_on_the_App_Store_Badge.svg"></img></a>
                        <a href="/androidDownload"><img className="md:w-36 md:mr-2 w-28" src="./Google_Play_Store_badge_EN.svg"></img></a>
                    </div>
                </div>

            </div>


            {/* Arrow */}
            <div className="relative flex">
                <div className="absolute flex justify-center items-center bg-red-2 rounded-full h-14 w-14 -bottom-7 left-1/2 transform -translate-x-1/2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="animate-bounce h-6 w-6 fill-white">
                        <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" />
                    </svg>
                </div>
            </div>

            {/* "About" Section */}
            <div className="flex md:flex-row flex-col w-full pt-20 pb-16 bg-red-2 md:px-20">

                <div className="flex w-full justify-center">
                    <div className="flex flex-shrink-0 flex-col items-end mr-3 mt-1">
                        <img className="h-[40px] object-contain -rotate-12 pr-2" src="./chalk-arrow.png"></img>
                        <img className="md:w-[150px] w-[100px] object-contain mt-7" src="./fedex-note.png"></img>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-start md:pl-0 pl-1 pb-6 text-white md:text-2xl text-xl font-bold md:whitespace-nowrap chalk-text w-52 md:w-auto">Hate missing your deliveries?</p>
                        <img className="h-[40px] object-contain -scale-x-100 rotate-90" src="./chalk-arrow.png"></img>
                        <img className="md:h-[220px] h-[140px] object-contain mt-5 flex-shrink-0" src="./package-stolen-photo.png"></img>
                    </div>
                </div>

                <div className="flex w-full justify-center md:mt-0 mt-14">
                    <div className="flex md:flex-shrink flex-col items-center justify-center md:px-0 px-5 md:ml-24">
                        <div className="bg-red-500 md:px-12 px-9 md:py-12 py-8 rounded-md shadow-md text-white text-sm md:text-base ">
                            <p className="text-2xl mb-5 font-medium">We've got your back.</p>
                            <p>
                            <p className="mb-5">
                                PickPackGo collaborates with local businesses that have agreed to receieve and 
                                hold packages for you. You'll never miss a package again!
                            </p>
                                <ul className="list-disc list-inside">
                                    <li>48% cheaper than USPS's solutions</li>
                                    <li>Pickup hours past 5PM</li>
                                    <li>Locations &lt;5 minutes away</li>
                                    <li>Bye-bye package theft!</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex flex-col text-base items-center my-auto ml-5 text-center py-14">

                <div className="flex flex-col w-11/12 px-10 mt-10 pb-10">
                    <div className="grid md:grid-cols-3 md:grid-rows-none grid-rows-3 gap-16">
                        <div className="flex flex-col items-center">
                            <img className="w-[70px] h-[70px] mb-10" src="./step1.png"></img>
                            <p className="text-2xl mb-4 text-red-2">Purchase a token or plan</p>
                            <p className="text-base">
                                Purchase a one-time pickup token or a subscription plan from one of our local business 
                                partners via our app.
                            </p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img className="w-[70px] h-[70px] mb-10" src="./step2.png"></img>
                            <p className="text-2xl mb-4 text-red-2">Set your delivery address</p>
                            <p className="text-base">
                                Set your package's delivery address to the location of the businesses from which you
                                purchased that token or subscription.
                            </p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img className="w-[70px] h-[70px] mb-10" src="./step3.png"></img>
                            <p className="text-2xl mb-4 text-red-2">Pick up your package</p>
                            <p className="text-base">
                                To pick up your packge once it arrives, go to the local business and show your purchase 
                                confirmation along with your ID.
                            </p>
                        </div>
                    </div>

                </div>

            </div> 


           


            {/* Footer */}
            <footer className="w-full py-2 bg-red-2 border text-red-300">
                <div className="text-center text-sm">
                    <p>&copy; {new Date().getFullYear()} PickPackGo. All rights reserved.</p>
                    <p>For business inquiries or customer service, please email contact@pickpackgo.app</p>
                </div>
            </footer>

        </div>
    )
}