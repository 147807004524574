import { useRef } from "react"
import { useNavigate } from "react-router-dom";

export const Plans = () => {
    
    return(
        <div className="flex flex-col justify-center">
            
            {/* Hero Image */}
            <div className="relative flex flex-row w-full h-auto bg-neutral-700">

                {/* Map */}
                <img className="absolute w-full h-full opacity-70 mix-blend-multiply brightness-50 contrast-200" 
                    style={{
                    backgroundImage: `url('./hero-image-map.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center 30%',
                    backgroundAttachment: 'fixed',
                }}/>

                <div className="relative flex flex-col w-full self-center md:mt-6 mt-3 md:py-5 py-2">
                        <div>
                            <div className="flex justify-center mr-5 items-center">
                                <img className="md:w-24 md:h-24 w-20 h-20 mr-6 slide-in-left" src="./logo.png"></img>
                                <p className="text-white items-center justify-center md:text-4xl text-3xl text-center font-normal">Pricing & Plans</p>
                            </div>
                        </div>
                </div>

            </div>

            {/* Content */}
            <div className="flex flex-col text-base items-center my-auto pb-20">

                <p className="text-center md:text-base text-sm md:px-32 px-5 py-6 mt-4 mb-4 bg-red-2 text-white">
                    PickPackGo offers a total of 3 different pricing plans depending on your needs. We understand that everyone has 
                    different purchasing habits, which is why we want to provide you with the flexibility of choosing an option that 
                    works best for you! 
                </p>

                <div className="flex flex-col mt-14 pb-8 w-full md:px-20 px-7">

                    <div className="grid md:grid-cols-3 gap-x-9 gap-y-5 justify-items-center items-center">
                        
                        <div className="md:order-1 order-1 relative flex items-center justify-center p-4 rounded-full border-2 border-neutral-400">
                            <img
                                className="w-[65px] h-[65px] p-[3px] object-contain"
                                src="./package.png"
                                alt="Image with circular border"
                            />
                        </div>
                        <div className="md:order-2 order-3 relative flex items-center justify-center p-4 rounded-full border-2 border-neutral-400 md:mt-0 mt-12">
                            <img
                                className="w-[65px] h-[65px] p-2 object-contain"
                                src="./1month.png"
                                alt="Image with circular border"
                            />
                        </div>
                        <div className="md:order-3 order-5 relative flex items-center justify-center p-4 rounded-full border-2 border-neutral-400 md:mt-0 mt-12">
                            <img
                                className="w-[65px] h-[65px] p-2 object-contain"
                                src="./3month.png"
                                alt="Image with circular border"
                            />
                        </div>

                        <div className="md:order-4 order-2 flex flex-col items-center justify-start border-2 border-neutral-300 rounded-lg py-7 px-5 w-full h-full">
                            <p className="text-lg mb-3 text-red-2">Pay per package</p>
                            <p className="text-base">
                                <ul className="list-disc list-inside text-sm">
                                    <li>One-time purchase</li>
                                    <li>Valid for one package per token</li>
                                    <li>Great for those who order occasionally</li>
                                </ul>
                            </p>
                        </div>
                        
                        <div className="md:order-5 order-4 flex flex-col items-center justify-start border-2 border-neutral-300 rounded-lg py-7 px-5 w-full h-full">
                            <p className="text-lg mb-3 text-red-2">1-month subscription</p>
                            <p className="text-base">
                                <ul className="list-disc list-inside text-sm">
                                    <li>Valid for one month (non-renewing)</li>
                                    <li>Unlimited number of package pickups</li>
                                    <li>Great for those who order frequently</li>
                                </ul>
                            </p>
                        </div>

                        <div className="md:order-6 order-6 flex flex-col items-center justify-start border-2 border-neutral-300 rounded-lg py-7 px-5 w-full h-full">
                            <p className="text-lg mb-3 text-red-2">3-month subscription</p>
                            <p className="text-base">
                                <ul className="list-disc list-inside text-sm">
                                    <li>Valid for one month (non-renewing)</li>
                                    <li>Unlimited number of package pickups</li>
                                    <li>Great for those who order very frequently</li>
                                </ul>
                            </p>
                        </div>

                    </div>
                </div>

            </div>

            <div className="flex text-2xl bg-neutral-200 justify-center py-16">
                <div className="flex flex-col md:px-48 px-10 items-center text-base">

                    <p className="text-3xl bg-white text-red-2 font-bold px-5 py-1 w-fit mb-16">
                        FAQ
                    </p>

                    <div className="mb-14">
                        <p className="bg-red-2 text-white px-1 w-fit mb-4">
                            How soon after a package is delivered can I pick it up?
                        </p>
                        <p className="text-sm">
                            Packages can be picked up immediately after you receive a notification from your carrier.  
                            All you need to do is show an ID (school ID is acceptable) and your confirmation pick-up 
                            receipt to an employee. Your confirmation receipt is accessible via the “Active Pickups” 
                            section in your settings. 
                        </p>
                    </div>

                    <div className="mb-14">
                        <p className="bg-red-2 text-white px-1 w-fit mb-4">
                            Why do I need to present an ID when picking up a package?
                        </p>
                        <p className="text-sm">
                        We do this to ensure that all packages are picked up by the correct person. Please ensure the 
                        receipt name of your package matches the name shown on your confirmation receipt.
                        </p>
                    </div>

                    <div className="mb-14">
                        <p className="bg-red-2 text-white px-1 w-fit mb-4">
                            If my package is lost or damaged, does PickPackGo cover this?
                        </p>
                        <p className="text-sm">
                        The contract for delivery exists between you and your chosen delivery carrier. We are not liable 
                        for packages lost, damaged, or stolen during transit. By utilizing our service you adhere to these 
                        terms. However, if proof is provided that a business is responsible for your lost or damaged package, 
                        we will issue you a refund of up to $25 of your package’s value and a full-refund for your token or 
                        subscription plan. Please direct any concerns or issues to our email: contact@pickpackgo.app.
                        </p>
                    </div>

                </div>
            </div>

            {/* Footer */}
            <footer className="w-full py-2 bg-red-2 border text-red-300">
                <div className="text-center text-sm">
                    <p>&copy; {new Date().getFullYear()} PickPackGo. All rights reserved.</p>
                    <p>For business inquiries or customer service, please email contact@pickpackgo.app</p>
                </div>
            </footer>
        </div>
    )

}