import { useRef } from "react"
import { useNavigate } from "react-router-dom";

export const Terms = () => {
    
    return(
        <div className="flex flex-col justify-center">
            
            {/* Hero Image */}
            <div className="relative flex flex-row w-full h-auto bg-neutral-700">

                {/* Map */}
                <img className="absolute w-full h-full opacity-70 mix-blend-multiply brightness-50 contrast-200" 
                    style={{
                    backgroundImage: `url('./hero-image-map.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center 30%',
                    backgroundAttachment: 'fixed',
                }}/>

                <div className="relative flex flex-col w-full self-center md:mt-6 mt-3 md:py-5 py-2">
                        <div>
                            <div className="flex justify-center mr-5 items-center">
                                <img className="md:w-24 md:h-24 w-20 h-20 mr-6 slide-in-left" src="./logo.png"></img>
                                <p className="text-white items-center justify-center md:text-4xl text-3xl text-center font-normal">Terms of Service</p>
                            </div>
                        </div>
                </div>

            </div>

            {/* Content */}
            <div className="flex flex-col text-base items-center my-auto ml-5">
                {/* "About" Section */}
                <div className="md:px-36 px-5 text-sm flex flex-col py-10 mb-5">

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Acceptance of Terms
                        </p>
                        By accessing and using our application ("App"), you agree to be bound by these Terms of Service ("Terms"). These Terms govern your use of our package receiving service that connects users with local businesses for package delivery purposes.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Service Description
                        </p>
                        Our App provides a platform that enables users to: 1) Designate local participating businesses as delivery points for packages
                        2) Purchase “tokens” or a subscription plan to utilize our service.
                        We act solely as an intermediary platform connecting users with participating businesses and do not provide delivery services directly.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Liability and Responsibilities
                        </p>
                        <p className="mt-2 italic">
                            Package Delivery and Transit
                        </p>
                        We are not a courier service and do not physically handle or transport packages.
                        The contract for delivery exists between you and your chosen delivery carrier.
                        We cannot be held liable for packages lost, damaged, or stolen during transit.
                        Claims for lost or damaged packages must be filed directly with the delivery carrier.
                        <p className="mt-2 italic">
                            Service Limitations
                        </p>
                        We do not guarantee the availability of participating businesses.
                        We are not responsible for packages once delivered to participating businesses. Guaranteed delivery is the responsibility of your chosen carrier. 
                        Users must retrieve packages within the specified timeframe set by participating businesses.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            User Obligations
                        </p>
                        You agree to: 
                            1) Provide accurate delivery information.
                            2) Not send prohibited or illegal items.
                            3) Retrieve packages within the designated timeframe.
                            4) Present valid identification when collecting packages.
                            5) Pay any applicable service fees.
                    </p>
                    
                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Package Claims and Disputes
                        </p>
                        <p className="mt-2 italic">
                            Lost or Damaged Packages
                        </p>
                        Claims must be filed directly with the delivery carrier.
                        By using our service, you acknowledge that PickPackGo is only liable for lost or damaged packages when supported by verifiable evidence.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Additional Terms
                        </p>
                        <p className="mt-2 italic">
                            Modifications
                        </p>
                            We reserve the right to modify these Terms at any time. Continued use of the App after changes constitutes acceptance of modified Terms.
                        <p className="mt-2 italic">
                            Termination
                        </p>
                        We may suspend or terminate your access for Terms violations. You may terminate your account at any time.

                    </p>

                    <p className="mb-5">
                        <p className="mb-1 text-lg">
                        Contact Information
                        </p>
                        For questions regarding these Terms, please contact us at contact@pickpackgo.app
                    </p>

                </div>
            </div>

            {/* Footer */}
            <footer className="w-full py-2 bg-red-2 border text-red-300">
                <div className="text-center text-sm">
                    <p>&copy; {new Date().getFullYear()} PickPackGo. All rights reserved.</p>
                    <p>For business inquiries or customer service, please email contact@pickpackgo.app</p>
                </div>
            </footer>
        </div>
    )
}