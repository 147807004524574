import { useRef } from "react"
import { useNavigate } from "react-router-dom";

export const PrivacyPolicy = () => {
    
    return(
        <div className="flex flex-col justify-center">
            
            {/* Hero Image */}
            <div className="relative flex flex-row w-full h-auto bg-neutral-700">

                {/* Map */}
                <img className="absolute w-full h-full opacity-70 mix-blend-multiply brightness-50 contrast-200" 
                    style={{
                    backgroundImage: `url('./hero-image-map.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center 30%',
                    backgroundAttachment: 'fixed',
                }}/>

                <div className="relative flex flex-col w-full self-center md:mt-6 mt-3 md:py-5 py-2">
                        <div>
                            <div className="flex justify-center mr-5 items-center">
                                <img className="md:w-24 md:h-24 w-20 h-20 mr-6 slide-in-left" src="./logo.png"></img>
                                <p className="text-white items-center justify-center md:text-4xl text-3xl text-center font-normal">Privacy Policy</p>
                            </div>
                        </div>
                </div>

            </div>


            {/* Content */}
            <div className="flex flex-col text-base items-center my-auto ml-5">
                {/* "About" Section */}
                <div className="flex flex-col py-10 mb-5 md:px-36 px-5 text-sm">

                    <p className="mb-7">
                        Aranza Rodriguez and Juliette Garcia built the PickPackGo app to provide an affordable and convenient solution to package pickup. This SERVICE is provided by Aranza Rodriguez and Juliette Garcia, and provides various purchasing plans for those seeking for a safe alternative to have their packages delivered. 
                        This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone chooses to use my Service.
                        If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at RideBuddies unless otherwise defined in this Privacy Policy.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Information Collection and Use
                        </p>
                        We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:
                        Third-Party Data: To facilitate account creation and logon process. If you choose to link your account with us to a third-party account (such as your Google account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. Link to the privacy policy of third-party service providers used by the app: Google Analytics for Firebase.
                        Bank and Credit Card Data: We utilize Stripe to facilitate in-app purchases. Stripe collections information regarding payments, including your name, purchase date, amount, and payment method. We collect this data to keep track of all in-app transactions. 
                        Cookies
                        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                        This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Service Providers
                        </p>
                        We may employ third-party companies and individuals due to the following reasons:
                        To facilitate our Service;
                        To provide the Service on our behalf;
                        To perform Service-related services; or
                        To assist us in analyzing how our Service is used.
                        We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Security
                        </p>
                        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                    </p>

                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Links to Other Sites
                        </p>
                        This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                    </p>
                    
                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                        Children’s Privacy
                        </p>
                        These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided me with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.
                    </p>
                    
                    <p className="mb-7">
                        <p className="mb-1 text-lg">
                            Changes to This Privacy Policy
                        </p>
                        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
                        This policy is effective as of 2024-11-07
                    </p>

                    <p className="mb-5">
                        <p className="mb-1 text-lg">
                        Contact Us
                        </p>
                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contact@pickpackgo.app
                    </p>

                </div>
            </div>

            {/* Footer */}
            <footer className="w-full py-2 bg-red-2 border text-red-300">
                <div className="text-center text-sm">
                    <p>&copy; {new Date().getFullYear()} PickPackGo. All rights reserved.</p>
                    <p>For business inquiries or customer service, please email contact@pickpackgo.app</p>
                </div>
            </footer>

        </div>
    )
}